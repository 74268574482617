import React, { Suspense, useState, useEffect} from "react"; 
import Header from '../../container/frontend/layout/header';
import Footer from '../../container/frontend/layout/footer';
import {Loader} from "../../components";
import '../../assets/frontend.scss';
const FrontLayout = (props) => { 
  var url = document.URL.split("/"); 
  const [showScrollData, setScrollData] = useState(false);

  const handleApiCall = () => {
    setScrollData(true)
  };

  // Scroll event handler
  const handleScroll = () => {
    if (!showScrollData) {
      handleApiCall();
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showScrollData]);



  return (
    <div>
      {url[3]!='login' && 
          <Header/>
      }
        <div>
          <Suspense fallback={<Loader/>}>
            {props.children}
          </Suspense>
        </div>
        {url[3]!='login' && 
          <Footer/>
        }
    </div>
  );
};
export default FrontLayout;
